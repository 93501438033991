<template>
  <!-- 查看 -->
  <el-dialog
    :visible.sync="dialogVisible"
    width="55%"
    :close-on-click-modal="false"
    @close="handleClose"
    :showClose="showclose"
  >
  <el-card style="margin-bottom:20px;margin-top:-34px;">
    <el-row style="background-color: #fff;">
      <el-col :span="2" style="text-align: center;">
        <img :src="WallerOperation.customPicurl?WallerOperation.customPicurl:'https://dev-uat-alanber.oss-cn-chengdu.aliyuncs.com/4FE9D265B1EF406FA50B208FE59A6A34.png'" alt="" />
      </el-col>
      <el-col :span="6" style="text-align: center;">客户姓名:&nbsp;{{ WallerOperation.customerName }}</el-col>
      <el-col :span="6" style="text-align: center;">客户手机号:&nbsp;{{ WallerOperation.tel }}</el-col>
      <el-col :span="5" style="text-align: center;">余额(￥):&nbsp;{{ WallerOperation.balance}}</el-col>
      <el-col :span="5" style="text-align: center;">积分:&nbsp;{{ WallerOperation.integral}}</el-col>
    </el-row>
    </el-card>
    <!-- <el-card> -->
     <el-card>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="钱包充值" name="wallet">
            <el-form ref="form" :model="form" label-width="80px" style="background-color: #fff; padding: 20px">
              <el-row>
                <el-col :span="8">
                  <el-form-item label="缴费时间:">
                    <el-date-picker
                      type="date"
                      placeholder="选择日期"
                      v-model="form.date1"
                      style="width: 100%"
                      value-format="yyyy-MM-dd HH:mm:ss"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="方式:">
                    <el-select v-model="selectvalue" placeholder="请选择">
                      <el-option label="充值" value="1"> </el-option>
                      <el-option label="缴费" value="2"> </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="金额:">
                    <el-input v-model="form.name" onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}" placeholder="￥"></el-input>
                  </el-form-item>
                </el-col>
              </el-row> </el-form
          ></el-tab-pane>
          <el-tab-pane label="新增积分" name="integral"
            ><el-form ref="form" :model="form" label-width="80px" style="background-color: #fff; padding: 20px">
              <el-row>
                <el-col :span="8">
                  <el-form-item label="添加时间:">
                    <el-date-picker
                      type="date"
                      placeholder="选择日期"
                      v-model="form.integraldate"
                      style="width: 100%"
                      value-format="yyyy-MM-dd HH:mm:ss"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="积分:">
                    <el-input v-model="form.integralNum" placeholder="积分数" onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="备注:">
                    <el-input v-model="form.integralval" placeholder="备注"></el-input>
                  </el-form-item>
                </el-col>
              </el-row> </el-form
          ></el-tab-pane>
        </el-tabs>
        </el-card>



    <!-- <el-form ref="form" :model="form" label-width="80px" class="wallet_main">
      <el-row style="display: flex;justify-content: space-between;">
        <el-col :span="7">
          <el-form-item label="充值时间:">
            <el-date-picker
              type="date"
              placeholder="选择日期"
              v-model="form.date1"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="充值/消费:">
            <el-select v-model="selectvalue" placeholder="请选择">
              <el-option label="充值" value="1"> </el-option>
              <el-option label="消费" value="2"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="充值金额:">
            <el-input v-model="form.topup" type = "text" name= "price" id = 'price' onkeyup= ""  placeholder="￥"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form> -->
    <!-- </el-card> -->
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="determineClick" :disabled="disabled">确 定</el-button>

<!-- 
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="determineClick">确 定</el-button> -->
    </span>
  </el-dialog>
</template>

<script>
// import { numberFormat } from '@/utils/utils'
import { postRequestAnthor } from '@/api/index'
export default {
  data() {
    return {
      dialogVisible: true,
      showclose: false,
      form1: {
        name: 'name',
        balance: 'balance',
        phone: 'phone',
        type: 'type',
      },
      form: {
        consumption: '',
        date1: '',
        date2: '',
        topup: '',
      },
      selectvalue: '1',
      activeName: 'wallet',
      disabled:false
    }
  },
  props: ['WallerOperation'],
  created() {
    console.log(this.WallerOperation);
    // this.WallerOperation.balance=numberFormat(this.WallerOperation.balance, 2, ".", ",")
  },
  methods: {
    // // 确定充值
    // determineClick(){
    //   postRequestAnthor('/customerwallet/recharge',{
    //         customerId:this.WallerOperation.customerId,
    //         amount:this.form.topup,
    //         type:this.selectvalue,
    //         createBy:1,
    //         updateTime:this.form.date1,
    //         updateBy:1
    //       }).then(res=>{
    //         if(res==true){
    //           this.$message({
    //             message:'缴费成功',
    //             type:'success'
    //           })
    //           this.dialogVisible=false
    //         }
    //       })
    // },
      // 确认添加
    determineClick() { 
      if(this.adduserdata != ""){
        this.disabled = true
      setTimeout(()=>{
        this.disabled = false
      },1500)
            if (this.activeName == 'wallet') {
        // 充值
        if(this.form.date1 == ''){
          this.$message({
          message: '请选择充值时间',
          type: 'warning'
        });
        }else if(this.selectvalue == ""){
          this.$message({
          message: '请选择充值方式',
          type: 'warning'
        });
        }else if(this.form.name == ""){
          this.$message({
          message: '输入充值金额',
          type: 'warning'
        });
        }else if(this.form.name != "" && this.selectvalue != "" && this.form.date1 != ""){
        postRequestAnthor('/customerwallet/recharge', {
          customerId: this.WallerOperation.customerId,
          amount: this.form.name,
          type: this.selectvalue,
          createBy: 1,
          updateTime: this.form.integraldate,
          updateBy: 1,
        }).then(res => {
          if (res == true) {
            this.$message({
              message: '缴费成功',
              type: 'success',
            })
            this.dialogVisible = false
          }
        })}
      } else if (this.activeName == 'integral') {
        // 新增积分
        if(this.form.integraldate == ''){
          this.$message({
          message: '请选择添加时间',
          type: 'warning'
        });
        }else if(this.form.integralNum == ""){
          this.$message({
          message: '输入新增积分数',
          type: 'warning'
        });
        }else if(this.form.integraldate != "" && this.form.integralNum != "" ){
          // console.log(this.form.integraldate, this.form.integralNum,this.form.integralval,this.adduserdata.id);
        postRequestAnthor('/customerwallet/addintegral', {
          integral: this.form.integralNum,
          reason: this.form.integralval,
          customerId: this.WallerOperation.customerId,
          updateTime: this.form.integraldate,
          create_by: 1,
        }).then(res => {
          if (res == true) {
            this.$message({
              message: '积分更改成功',
              type: 'success',
            })
            this.dialogVisible = false
          }
        })}
      }
      }
  
    },
    handleClose() {
      this.$emit('closeWalletOperation')
    },
    handleClick(tab) {
      this.tabname = tab.name
      console.log(tab.name)
    },
  },
}
</script>

<style lang="scss" scoped>
img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.el-row {
  display: flex;
  align-items: center;
}
.el-form > .el-row {
  display: flex;
  justify-content: center;
}
.wallet_main{
  // padding:20px;
  margin-top:20px;
  background-color: #fff;
}
</style>